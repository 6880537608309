import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ShapeContact from "../components/shapes/ShapeContact"

const CookiePolicy = () => (
  <Layout>
    <SEO title="Polityka Cookies" />

    <main className="default_page">
      <ShapeContact />

      <section className="page_header_wrapper">
        <h1 className="page_header" data-text="&#60;Polityka Cookies/&#62;">
          &#60;Polityka Cookies/&#62;
        </h1>
      </section>

      <section className="container-fluid default_section_1">
        <p>
          Niniejsza Polityka dotycząca Ciasteczek odnosi się do strony
          www.forsecure.pl Przez używanie serwisu www.forsecure.pl wyrażasz
          zgodę na używanie ciasteczek zgodnie z tą Polityką Ciasteczek. Jeżeli
          nie zgadzasz się na używanie przez nas ciasteczek, powinieneś zmienić
          ustawienia swojej przeglądarki w odpowiedni sposób lub zrezygnować z
          używania strony www.forsecure.pl.
        </p>

        <h3>Co to są ciasteczka?</h3>
        <p>
          Ciasteczka (ang. cookies) to niewielkie pliki, zapisywane i
          przechowywane na twoim komputerze, tablecie lub smartphonie podczas
          gdy odwiedzasz różne strony w internecie. Ciasteczko zazwyczaj zawiera
          nazwę strony internetowej, z której pochodzi, „długość życia”
          ciasteczka (to znaczy czas jego istnienia), oraz przypadkowo
          wygenerowany unikalny numer służący do identyfikacji przeglądarki, z
          jakiej następuje połączenie ze stroną internetową.
        </p>

        <h3>Do czego używamy ciasteczek?</h3>
        <p>www.forsecure.pl używa ciasteczek w różnych celach:</p>
        <ul>
          <li>by serwis działał szybciej i był łatwiejszy w użyciu,</li>
          <li>
            do zbierania anonimowych, zagregowanych statystyk, które pozwalają
            nam zrozumieć jak ludzie używają naszych stron i pomagają w
            poprawianiu ich funkcjonalności i zawartości.
          </li>
          <li>
            Używając ciasteczek w wyżej opisany sposób nigdy nie identyfikujemy
            tożsamości użytkowników na podstawie informacji przechowywanych w
            ciasteczkach.
          </li>
        </ul>

        <h3>Jak długo przechowywane są dane w ciasteczkach?</h3>
        <p>
          Na stronie www.forsecure.pl mogą być używane dwa rodzaje ciasteczek –
          sesyjne oraz stałe. Te pierwsze pozostają na Twoim urządzeniu jedynie
          podczas korzystania ze strony www.forsecure.pl. Ciasteczka stałe
          pozostają na Twoim urządzeniu tak długo jak długo mają ustawiony czas
          życia lub do momentu kiedy je usuniesz.
        </p>

        <h3>Rodzaje ciasteczek używanych na stronie www.forsecure.pl</h3>
        <p>
          Konieczne do działania stron – Niezbędne do prawidłowego
          funkcjonowania strony www.forsecure.pl, pozwalają Ci na poruszanie się
          po nich oraz używanie ich elementów. Przykładowo mogą zapamiętywać
          poprzednie czynności (np. otwarte teksty) podczas wracania na stronę w
          tej samej sesji.
        </p>
        <p>
          Poprawiające wydajność – Zbieranie informacji o tym jak odwiedzający
          korzystają ze strony www.forsecure.pl poprzez dostarczanie informacji
          na temat obszarów które odwiedzają, czasu jaki na nich spędzają oraz
          problemów jakie na nich napotykają, jak np. komunikaty o błędach. To
          pozwala nam poprawiać działanie strony www.forsecure.pl.
        </p>

        <h3>Czy używamy ciasteczek podmiotów trzecich?</h3>
        <p>
          Tak, korzystając z serwisu www.forsecure.pl możesz otrzymywać
          ciasteczka pochodzące od współpracujących z www.forsecure.pl podmiotów
          trzecich takich jak np. Facebook czy Google. Więcej informacji na
          temat tych ciasteczek możesz znaleźć na stronach internetowych
          poszczególnych podmiotów trzecich.
        </p>

        <h3>
          W jaki sposób mogę zmienić ustawienia dot. ciasteczek albo je usunąć?
        </h3>
        <p>
          Większość przeglądarek internetowych jest początkowo ustawionych na
          automatyczne przyjmowanie ciasteczek. Możesz jednak zmienić ustawienia
          przeglądarki tak, aby ciasteczka były blokowane – w całości lub w
          jakiejś części, np. tylko od stron trzecich, albo aby każdorazowo
          otrzymywać komunikat w momencie kiedy ciasteczka są wysyłane na Twoje
          urządzenie. Pamiętaj jednak, że jeżeli zablokujesz używane przez nas
          ciasteczka, może to negatywnie wpłynąć na wygodę korzystania ze strony
          www.forsecure.pl, na przykład możesz nie być w stanie odwiedzić
          pewnych obszarów strony www.forsecure.pl bądź nie otrzymywać
          spersonalizowanych informacji podczas ich przeglądania. Uniemożliwisz
          nam także zbieranie anonimowych informacji nt. używania naszych stron
          w celu stałego poprawiania zawartości strony www.forsecure.pl.
        </p>
      </section>
    </main>
  </Layout>
)

export default CookiePolicy
